<template>
        <v-container fluid pa-0 >
         
            <v-row  align="center"  justify="center" style="height:100vh"  dense >
               <v-col cols="12" align="center">

                  <v-card width="300">
                     <v-toolbar dark >
                        <v-toolbar-title>
                        <v-avatar tile>
                           <v-img src="/img/monster.png"></v-img>&nbsp;&nbsp;
                        </v-avatar>
                           Login Gena Academia
                           </v-toolbar-title>
                     </v-toolbar>
                     <v-card-text >
                        <v-form>
                           <v-text-field
                              v-model="user"
                              prepend-icon="mdi-account"
                              name="login"
                              label="Login"
                              type="text"
                           ></v-text-field>
                           <v-text-field
                              v-model="password"
                              id="password"
                              prepend-icon="mdi-lock"
                              name="password"
                              label="Senha"
                              type="password"
                           ></v-text-field>
                        </v-form>
                     </v-card-text>
                     <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn  @click="login" :loading="loading">Login</v-btn>
                     </v-card-actions>
                  </v-card>
               </v-col>
            </v-row>
         </v-container>
</template>

<script>
import axios from "axios";
  export default {
    data:()=>({
      user: '', password:'', loading: false, 
    }),
    methods:{
      async login(){
         if(!this.password || !this.user){
            return this.$store.dispatch('alerts/error',  'Login e senha são obrigatórios')
         }
         this.loading = true
        await axios.post(this.$store.state.baseUrl+'/login', {user: this.user, password: this.password})
          .then(({data}) => {
            this.$store.commit('login/setToken', data.token)
            this.$store.commit('login/setIsAuthenticated', true)
            this.$store.commit('setAxios',axios.create({baseURL: this.$store.state.baseUrl, headers:{token: data.token}}))
            this.$store.dispatch('socketConnection')
            //window.socket = new WebSocket(this.$store.state.wss, "$2a$10$grLCebp4Vz4EgQWb")
            this.$router.push('/')
          }).catch(err=>{
            this.password = ''
            this.$store.dispatch('alerts/error', err?.response?.data?.error || 'Falha ao tentar se comunicar com o servidor, tente novamente mais tarde'+err.stack)
         })
         this.loading = false
      }
    },
    mounted(){
      localStorage.clear();
      (window.socket?.readyState !== 1) || window.socket.close()
      !this.$store.state.peer || this.$store.state.peer.disconnect()
      this.$store.commit('setPeer', null)
    }
  }
</script>

<style lang="scss" scoped>

</style>